import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { FileUploaderConfig } from '../components/file-uploader';
import { TaskReqBody, TaskReqResp } from '@app/pages/task/+data-access';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  private apiUrl = `${environment.apiUrl}/attachments`;
  
  private http = inject(HttpClient);
  
  dedup(files: File[]) {
    return files.reduce((results: File[], b: File) => {
      if (!results.some(x => x.name === b.name)) {
        results.push(b);
      }
      return results;
    }, []);
  }

  uploadFiles(file: File[], config: FileUploaderConfig ): TaskReqBody.UploadAttachments {
    const attachments = this.dedup([...file])
    const configUpload: TaskReqBody.UploadAttachments = {
      attachments,
      propertyId: config.propertyId,
      modelId: config.modelId,
      modelType: config.model
    }
    return configUpload;
  }

  sendAttachments(payload: TaskReqBody.UploadAttachments): 
    Observable<{ progress: number, response?: TaskReqResp.UploadAttachments }> 
  {
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });

    const body = new FormData();
    body.append('modelId', payload.modelId);
    body.append('modelType', payload.modelType);
    body.append('propertyId', payload.propertyId);

    // Append each file
    payload.attachments.forEach((file: any) => {
      body.append('attachments[]', file, file.name);
    });

    // Track the progress and response
    return this.http.post<any[]>(this.apiUrl, body, {
      headers,
      withCredentials: true,
      reportProgress: true, 
      observe: 'events' 
    }).pipe(
      map(event => this.getEventMessage(event)), 
      catchError(this.handleError)               
    );
  }

  private handleError(error: any): Observable<{ progress: number, response?: any }> {
    console.error('An error occurred:', error); 
    return of({ progress: 0, response: undefined });  
  }
  
  private getEventMessage(event: HttpEvent<any>): { progress: number, response?: any } {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
        return { progress };

      case HttpEventType.Response:
        return { progress: 100, response: event.body };

      default:
        return { progress: 0 };
    }
  }
  
  deleteFile(id: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${this.apiUrl}/${id}`, {
      withCredentials: true
    });
  }
}
